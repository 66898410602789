// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@images/svg/pomg-logo-grey.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@images/svg/fnherstal-logo-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --rgb-primary: 19, 25, 43;
  --rgb-bg: 255, 255, 255;
  --c-primary: #223493;
  --c-primary-dark: #13192B;
  --c-secondary: #05C4F2;
  --c-link: var(--c-primary-dark);
  --c-link-hover: var(--c-primary);
  --c-bg: #fff;
  --c-grey: #c1c1c1;
  --c-grey-light: #EEF2F3;
  --c-danger: #dc3545;
  --c-success: #28a745;
  --c-warning: #ffc107;
  /** Text colors */
  --c-text: var(--c-primary-dark);
  --c-title: var(--c-primary);
  /** Btn colors */
  --c-btn-text: var(--c-primary);
  --c-btn-bg: var(--c-bg);
  --c-btn-text-hover: var(--c-bg);
  --c-btn-bg-hover: var(--c-primary);
  --c-bg-grey:#eff2f3;
}

#wpadminbar .quicklinks .menupop ul.ab-sub-secondary,
#wpadminbar .quicklinks .menupop ul.ab-sub-secondary .ab-submenu,
#wpadminbar .quicklinks .menupop .ab-sub-wrapper,
#wpadminbar .quicklinks .menupop .shortlink-input {
  background-color: var(--c-primary-dark);
}

#wpadminbar .quicklinks > ul > li#wp-admin-bar-wp-logo {
  background: var(--c-primary-dark);
}

#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon::before {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
}

#adminmenuback,
#adminmenuwrap,
#adminmenu,
#adminmenu .wp-submenu {
  background-color: var(--c-primary-dark);
}

body.login, body.wp-admin {
  background-color: var(--c-bg-grey);
}

body.login #wpadminbar, body.wp-admin #wpadminbar {
  background-color: var(--c-primary-dark);
}

body.login.wp-core-ui .button, body.wp-admin.wp-core-ui .button {
  background-color: var(--c-primary-dark);
  border-color: var(--c-primary-dark);
  color: var(--c-bg);
}

body.login.wp-core-ui .button:hover, body.wp-admin.wp-core-ui .button:hover {
  background-color: var(--c-primary);
  border-color: var(--c-primary);
  color: var(--c-bg);
}

body.login.wp-core-ui .button-link,
body.login.wp-core-ui a, body.wp-admin.wp-core-ui .button-link,
body.wp-admin.wp-core-ui a {
  color: rgba(var(--c-primary-dark), 0.9);
}

body.login.wp-core-ui .button-link.acf-icon.dark,
body.login.wp-core-ui a.acf-icon.dark, body.wp-admin.wp-core-ui .button-link.acf-icon.dark,
body.wp-admin.wp-core-ui a.acf-icon.dark {
  color: white;
}

body.login.wp-core-ui .button-link:hover,
body.login.wp-core-ui a:hover, body.wp-admin.wp-core-ui .button-link:hover,
body.wp-admin.wp-core-ui a:hover {
  color: var(--c-bg-grey);
}

body.login.wp-core-ui .acf-fc-popup a, body.wp-admin.wp-core-ui .acf-fc-popup a {
  color: white;
}

body.login .image-wrap, body.wp-admin .image-wrap {
  max-width: 300px !important;
}

body.login .widefat th .post-state,
body.login .widefat td .post-state, body.wp-admin .widefat th .post-state,
body.wp-admin .widefat td .post-state {
  color: var(--c-primary);
}

body .striped > tbody > :nth-child(odd),
body ul.striped > :nth-child(odd),
body .alternate {
  background-color: var(--c-bg-grey);
}

body #adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
body #adminmenu li.current a.menu-top,
body #adminmenu .wp-menu-arrow,
body #adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
body #adminmenu .wp-menu-arrow div {
  background-color: var(--c-primary);
}

body #adminmenu a:hover,
body #adminmenu li.menu-top > a:focus,
body #adminmenu .wp-submenu a:hover,
body #adminmenu .wp-submenu a:focus {
  color: var(--c-bg-grey);
}

body.login {
  background: var(--c-primary-dark);
}

body.login .message,
body.login .success,
body.login #login_error,
body.login form {
  background-color: var(--c-bg-grey);
}

body.login #backtoblog a,
body.login .privacy-policy-page-link a,
body.login #nav a {
  color: var(--c-bg);
}

body.login #backtoblog a:hover,
body.login .privacy-policy-page-link a:hover,
body.login #nav a:hover {
  color: var(--c-bg-grey);
}

body #login h1 a,
body .login h1 a {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  height: 100%;
  width: 100%;
  min-height: 12rem;
  background-size: contain;
  background-repeat: no-repeat;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
